import React from 'react';
import SinglePoem from '../../../components/singlePoem';
import Paper from '@mui/material/Paper'

export default function Poem() {
  return (
    <SinglePoem title="Anne Frank's Wall" >
        <p>
        On to the wall,<br></br>
        pale brown paper<br></br>
        covering the borrowed plaster,<br></br>
        she pasted with the<br></br>
        brush and bottle<br></br>
        they sneaked to her,<br></br>
        carefully clipped images<br></br>
        from contraband<br></br>
        Hollywood monthlies:<br></br>
        Ginger Rogers grinning<br></br>
        from her lot-built equator,<br></br>
        Ray Milland’s dark gloss<br></br>
        wreathed in ethereal cigarette,<br></br>
        England’s royal princesses<br></br>
        in frills on lawns,<br></br>
        and Deanna Durbin full of sound<br></br>
        and apprehensive thrill –<br></br>
        What did that thin little girl<br></br>
        hiding<br></br>
        in that thin little house<br></br>
        up those thin stairs<br></br>
        feel<br></br>
        as she would unscrew the cap,<br></br>
        dip, and press firm,<br></br>
        smoothing the air bubbles<br></br>
        from her pasted-up gallery –<br></br>
        the acrid bite of the<br></br>
        glue’s aroma<br></br>
        wafting above her head like<br></br>
        Ray’s smoky tendrils,<br></br>
        and with Ginger,<br></br>
        thousands of miles away,<br></br>
        going through her steps<br></br>
        one more time,<br></br>
        knowing nothing of the brown paper wall,<br></br>
        carefully tended,<br></br>
        somewhere in that wide land<br></br>
        behind a narrow Dutch bookshelf?<br></br>
        </p>
    </SinglePoem>
      );
}
